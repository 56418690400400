import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactGA from "react-ga";

import NavItem from "./components/NavItem";
import Carlinhos from "./components/Carlinhos";
import Modal from "../Modal";

import "./styles.css";

import imageSymbolRJ from "./images/simbolo_riodejaneiro.png";
import imageBrasao from "./images/brasao.png";
import imageLogo from "./images/logo.svg";
import imageTransparencyIcon from "./images/transparency_icon.png";
import api from "../../services/api";

function Header() {
  const [hideModal, setHideModal] = useState(true);
  const { accessType } = useParams();
  const [carlinhosInitialState] = useState(
    window.location.pathname.split("/").length !== 2
  );
  const [ribbon, setRibbon] = useState({
    Titulo: null,
    Nome_Arquivo: null,
    materia: null,
  });
  const navLinks = [
    {
      title: "Institucional",
      links: [
        {
          title: "Crefito-2",
          href: `/${accessType}/crefito2`,
        },
        {
          title: "Fisioterapia",
          href: `/${accessType}/fisioterapia`,
        },
        {
          title: "Terapia Ocupacional",
          href: `/${accessType}/terapia-ocupacional`,
        },
        {
          title: "Legislação",
          href: `/${accessType}/transparencia/normativas_crefito2`,
        },
        {
          title: "Comunicação",
          href: `/${accessType}/comunicacao`,
        },
        {
          title: "Ações Sociais",
          href: `/${accessType}/acoes_sociais`,
        },
        {
          title: "Eleições",
          href: `/${accessType}/eleicoes`,
        },
        {
          title: "Podcast",
          href: `/${accessType}/podcast`,
        },
        {
          title: "Perguntas Mais Frequentes",
          href: `/${accessType}/conteudo/4836`,
        },
      ],
    },
    {
      title: "Transparência e Prestação de Contas",
      img: imageTransparencyIcon,
      href: `https://accredit.incorp.tech/incorpweb/portal/?env=crefitorj`,
      external: true,
    },
    {
      title: "Licitações",
      href: `/${accessType}/licitacoes`,
    },
    {
      title: "Acesso ao sistema",
      professional: true,
      links: [
        {
          title: "Login Profissional",
          href: "https://www.crefito2.com.br/spw/consultacadastral/TelaLogin.aspx",
          external: true,
        },
        {
          title: "Login Empresas e Consultórios",
          href: "https://www.crefito2.com.br/spw/consultacadastral/TelaLoginEmpresa.aspx",
          external: true,
        },
      ],
    },
    {
      title: "Ouvidoria",
      icon: "record_voice_over",
      href: `https://falabr.cgu.gov.br/publico/Manifestacao/SelecionarTipoManifestacao.aspx`,
      external: true,
    },
  ];

  function returnDropdownItems(link, index) {
    if (
      accessType === "home_profissional" ||
      (accessType !== "home_profissional" && !link.professional)
    ) {
      if (link.links) {
        return (
          <div key={index}>
            <li key={`button-${index}`} className="nav-item dropdown">
              <button
                className="nav-link dropdown-toggle dropdown-button dropdown-nav-link"
                href="#"
                data-toggle="dropdown"
              >
                {link.title}
              </button>
              <ul className="dropdown-menu">
                {link.links.map((innerLink, innerIndex) => {
                  return (
                    <li key={`${index}-${innerIndex}`}>
                      <a className="dropdown-item" href={innerLink.href}>
                        {innerLink.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </li>
            {link.links.map((innerLink, innerIndex) => {
              return (
                <li
                  key={`${index}-${innerIndex}-hidden`}
                  className="nav-item mobile"
                >
                  <a className="nav-link hidden-nav-link" href={innerLink.href}>
                    {innerLink.title}
                  </a>
                </li>
              );
            })}
          </div>
        );
      } else {
        return (
          <NavItem
            key={index}
            icon={link.icon}
            img={link.img}
            external={link.external ?? false}
            title={link.title}
            href={link.href}
          />
        );
      }
    }
  }

  function handleDropdownClick() {
    document.getElementById("navbarNavDropdown").classList.toggle("show");

    // const hiddenNavLinks = document.getElementsByClassName("hidden-nav-link");

    // hiddenNavLinks.forEach((hiddenNavLink) => {
    //   hiddenNavLink.toggleAttribute("hidden");
    // });

    // const dropdownNavLinks =
    //   document.getElementsByClassName("dropdown-nav-link");

    // dropdownNavLinks.forEach((dropdownNavLink) => {
    //   dropdownNavLink.toggleAttribute("hidden");
    // });

    // if (accessType === "home_profissional") {
    //   const hiddenNavLinksCivil = document.getElementsByClassName(
    //     "hidden-nav-link-civil"
    //   );
    //   hiddenNavLinksCivil.forEach((hiddenNavLinkCivil) => {
    //     hiddenNavLinkCivil.toggleAttribute("hidden");
    //   });
    // }
  }

  // Google Analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    async function fetchRibbon() {
      await api
        .get("/laco")
        .then((response) => {
          setRibbon(response.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchRibbon();
  }, []);

  useEffect(() => {
    if (hideModal) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [hideModal]);

  if (accessType === "home_profissional") {
    return (
      <div className="container-fluid" style={{ padding: 0 }}>
        <nav className="navbar navbar-expand-lg">
          <img
            className="cristo"
            src={imageSymbolRJ}
            alt="Imagem do Cristo Redentor"
          />

          <div className="container">
            <div className="navbar-brand">
              <Link to={`/${accessType}`}>
                <img className="logobrasao" src={imageBrasao} alt="" />
                <img className="logobrasaos" src={imageLogo} alt="" />
              </Link>
              <img
                className="laco"
                src={ribbon.Nome_Arquivo}
                title={ribbon.Titulo}
                alt=""
                onClick={() => {
                  setHideModal(false);
                }}
              />
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleDropdownClick}
            >
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {navLinks.map((value, index) =>
                  returnDropdownItems(value, index)
                )}
              </ul>
            </div>
          </div>
        </nav>

        <Modal
          id={"modal"}
          hidden={hideModal}
          text={ribbon.materia}
          setState={setHideModal}
        />

        <Carlinhos initialState={carlinhosInitialState} />
      </div>
    );
  } else {
    return (
      <div className="container-fluid" style={{ padding: 0 }}>
        <nav className="navbar navbar-expand-lg">
          <img
            className="cristo"
            src={imageSymbolRJ}
            alt="Imagem do Cristo Redentor"
          />

          <div className="container">
            <div className="navbar-brand">
              <Link to={`/${accessType}`}>
                <img className="logobrasao" src={imageBrasao} alt="" />
                <img className="logobrasaos" src={imageLogo} alt="" />
              </Link>
              <img
                className="laco"
                src={ribbon.Nome_Arquivo}
                title={ribbon.Titulo}
                alt=""
                onClick={() => {
                  setHideModal(false);
                }}
              />
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleDropdownClick}
            >
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {navLinks.map((value, index) =>
                  returnDropdownItems(value, index)
                )}
              </ul>
            </div>
          </div>
        </nav>

        <Modal
          id={"modal"}
          hidden={hideModal}
          text={ribbon.materia}
          setState={setHideModal}
        />

        <Carlinhos initialState={carlinhosInitialState} />
      </div>
    );
  }
}

export default Header;
