import React from "react";
// import { useParams } from "react-router-dom";ssssssssss

import "./styles.css";

export default function SocialNetworks() {
  // const { accessType } = useParams();s

  return (
    <div className="redes__sociais">
      {/* <a
        target="_blank"
        rel="noopener noreferrer"
        href={`/${accessType}/fale_conosco`}
      >
        <div className="rede">
          <span className="material-icons">campaign</span>
        </div>
      </a> */}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/company/crefito-2"
      >
        <div className="rede">
          <i className="fab fa-linkedin"></i>
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/crefito2"
      >
        <div className="rede">
          <i className="fab fa-facebook-f"></i>
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/crefito2/"
      >
        <div className="rede">
          <i className="fab fa-instagram"></i>
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.twitter.com/crefito2"
      >
        <div className="rede">
          <i className="fab fa-twitter"></i>
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UCvqZh_pif-knE15ymgM9Vxg"
      >
        <div className="rede">
          <i className="fab fa-youtube"></i>
        </div>
      </a>
    </div>
  );
}
