import api from "../../../../../../../services/api";

import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";

import "./styles.css";

export default function FormResolution538() {
  const [cep, setCep] = useState("");
  const [cepInfo, setCepInfo] = useState({
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    ibge: "",
    gia: "",
    ddd: "",
    siafi: "",
  });
  const { register, handleSubmit } = useForm();

  const cleanForm = useCallback(() => {
    document.getElementById("cep").value = "";
    document.getElementById("place").value = "";
    document.getElementById("neighbourhood").value = "";
    document.getElementById("city").value = "";
    document.getElementById("uf").value = "";
    disableAddress();
  }, []);

  useEffect(() => {
    async function fetchCepInfo() {
      if (cep !== "") {
        await fetch(`https://viacep.com.br/ws/${cep}/json/`)
          .then((res) => res.json())
          .then((apiCepInfo) => {
            setCepInfo(apiCepInfo);
          });
      }
    }
    fetchCepInfo();
  }, [cep]);

  useEffect(() => {
    function fillForm() {
      document.getElementById("place").value = cepInfo.logradouro;
      document.getElementById("neighbourhood").value = cepInfo.bairro;
      document.getElementById("uf").value = cepInfo.uf;
      document.getElementById("city").value = cepInfo.localidade;
    }
    if (!cepInfo.erro) {
      fillForm();
    } else {
      cleanForm();
      alert("CEP inválido. Por favor, digite um CEP válido");
    }
  }, [cepInfo, cleanForm]);

  function enableAddress() {
    document.getElementById("place").removeAttribute("disabled");
    document.getElementById("neighbourhood").removeAttribute("disabled");
    document.getElementById("city").removeAttribute("disabled");
    document.getElementById("uf").removeAttribute("disabled");
    document.getElementById("number").removeAttribute("disabled");
    document.getElementById("complement").removeAttribute("disabled");
  }

  function disableAddress() {
    document.getElementById("place").setAttribute("disabled", true);
    document.getElementById("neighbourhood").setAttribute("disabled", true);
    document.getElementById("city").setAttribute("disabled", true);
    document.getElementById("uf").setAttribute("disabled", true);
    document.getElementById("number").setAttribute("disabled", true);
    document.getElementById("complement").setAttribute("disabled", true);
  }

  async function onSubmit(data) {
    try {
      const result = await api.post(
        "/form/desconto-anuidade/resolucao-538",
        data
      );
      if (!alert(result.data.message)) {
        window.scroll(0, 0);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      if (!alert("Ocorreu um erro! Tente novamente mais tarde.")) {
        window.scroll(0, 0);
        window.location.reload();
      }
    }
  }

  return (
    <div className="denuncia__form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__title">
          <p>Formulário para Solicitação de 50% de Desconto</p>
        </div>

        <div className="form__options">
          <div className="option__column">
            <p>Selecione sua categoria</p>
            <ul>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="fisioterapia"
                />
                Fisioterapia
              </li>
              <li>
                <input
                  {...register("profile", { required: true })}
                  type="radio"
                  value="terapia ocupacional"
                />
                Terapia Ocupacional
              </li>
            </ul>
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <input
            {...register("register", { required: true })}
            type="text"
            placeholder="Número de registro"
          />
          <input
            {...register("name", { required: true })}
            type="text"
            placeholder="Nome completo"
          />
          <div className="form__group--half">
            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="Endereço de E-Mail"
            />
            <input
              {...register("cel", { required: true })}
              type="tel"
              placeholder="Telefone"
            />
          </div>
        </div>

        <div className="form__divisor"></div>

        <div className="form__group">
          <label htmlFor="cep">CEP:</label>
          <input
            id="cep"
            {...register("cep", { required: true })}
            type="number"
            placeholder="CEP"
            onBlur={(e) => {
              var cep = e.target.value.replace(/\D/g, "");

              if (cep !== null && cep !== "") {
                var cepRegex = /^[0-9]{8}$/;

                if (cepRegex.test(cep)) {
                  enableAddress();
                  setCep(cep);
                } else {
                  cleanForm();
                  alert("Formato de CEP inválido.");
                }
              } else {
                cleanForm();
              }
            }}
          />
          <label htmlFor="place">Endereço:</label>
          <input
            id="place"
            {...register("place", { required: true })}
            type="text"
            placeholder="Logradouro"
            disabled
          />
          <div className="form__group--half">
            <input
              id="number"
              {...register("number", { required: true })}
              type="text"
              placeholder="Número"
              disabled
            />
            <input
              id="complement"
              {...register("complement")}
              type="text"
              placeholder="Complemento"
              disabled
            />
          </div>
          <input
            id="neighbourhood"
            {...register("neighbourhood", { required: true })}
            type="text"
            placeholder="Bairro"
            disabled
          />
          <div className="form__group--half">
            <input
              id="uf"
              {...register("uf", { required: true })}
              type="text"
              defaultValue=""
              placeholder="Estado (UF)"
              disabled
            />
            <input
              id="city"
              {...register("city", { required: true })}
              type="text"
              defaultValue=""
              placeholder="Cidade"
              disabled
            />
          </div>
          <p>
            <br />
            Venho requerer{" "}
            <strong>50% DE DESCONTO DE PAGAMENTO DE ANUIDADES</strong>, nos
            termos da{" "}
            <a
              href="https://www.coffito.gov.br/nsite/?p=19411"
              style={{ color: "var(--secondary-color)" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              RESOLUÇÃO COFFITO Nº. 538 de 27 de setembro de 2021
            </a>
            , por me enquadrar nos requisitos necessários conforme disposto no
            artigo 6º, ou seja:
          </p>
          <div className="user_authorization">
            <input
              id="userRequirement"
              {...register("userRequirement", { required: true })}
              type="checkbox"
              value="eligible"
            />
            <label htmlFor="userRequirement">
              30 (trinta) anos completos ou mais de inscrição.
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="firstUserAuthorization"
              {...register("firstUserAuthorization", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="firstUserAuthorization">
              Autorizo o envio de informações para meu e-mail.
            </label>
          </div>
          <div className="user_authorization">
            <input
              id="secondUserAuthorization"
              {...register("secondUserAuthorization", { required: true })}
              type="checkbox"
              value="authorized"
            />
            <label htmlFor="secondUserAuthorization">
              Declaro, para fins de direito, sob as penas da lei, que as
              informações apresentadas são verdadeiras e autênticas.
            </label>
          </div>
          <input id="submit" className="button" type="submit" value="Enviar" />
        </div>
      </form>
    </div>
  );
}
